<template>
    <div class="article_page">
        <div class="content">
            <div class="container__articles">
                <div class="container__articles_frame">
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Articles",
        metaInfo: {
            title: 'GreatRay ',
            titleTemplate: '%s: Новости',
        }
    }
</script>

<style scoped>
    .container__articles{

    }
</style>